.banner {
    margin: 20px auto;
    max-width: 100%;
    background-color: aqua;
    height: 500px;
  }
  
  .banner {
    position: relative; /* Ensure proper stacking context */
    z-index: 2; /* Set z-index to be lower than the hamburger menu */
  }
  
  .banner img {
    width: auto;
    height: 300px;
    padding: 50px;
    object-fit: contain;
  }
  
  .slick-dots {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
  }
  
  .slick-dots li {
    display: inline-block;
    margin: 0 5px;
  }
  
  .slick-dots li button {
    font-size: 10px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }
  
  .slick-dots li.slick-active button {
    background-color: #fff;
  }
  
  .prev-arrow,
  .next-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: #fff;
    padding: 10px;
    cursor: pointer;
  }
  
  .prev-arrow {
    left: 10px;
  }
  
  .next-arrow {
    right: 10px;
  }
  
  .carousel-caption {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 10px;
    text-align: center;
  }
  
  /* Mobile devices */
  @media (max-width: 480px) {
    .banner {
      height: 300px;
    }
    .banner img {
      height: 150px;
      padding: 20px;
    }
    .carousel-caption {
      bottom: 5%;
      padding: 8px 5px;
    }
    .labell {
      font-size: 14px;
    }
    .paraa {
      font-size: 12px;
    }
  }
  
  /* iPads, Tablets */
  @media (min-width: 481px) and (max-width: 768px) {
    .banner {
      height: 400px;
    }
    .banner img {
      height: 200px;
      padding: 30px;
    }
    .carousel-caption {
      bottom: 8%;
      padding: 10px 5px;
    }
    .labell {
      font-size: 16px;
    }
    .paraa {
      font-size: 14px;
    }
  }
  
  /* Small screens, laptops */
  @media (min-width: 769px) and (max-width: 1024px) {
    .banner {
      height: 450px;
    }
    .banner img {
      height: 250px;
      padding: 40px;
    }
    .carousel-caption {
      bottom: 10%;
      padding: 12px 10px;
    }
    .labell {
      font-size: 18px;
    }
    .paraa {
      font-size: 16px;
    }
  }
  
  /* Desktops, large screens */
  @media (min-width: 1025px) and (max-width: 1200px) {
    .banner {
      height: 550px;
    }
    .banner img {
      height: 350px;
      padding: 50px;
    }
    .carousel-caption {
      bottom: 12%;
      padding: 15px 10px;
    }
    .labell {
      font-size: 20px;
    }
    .paraa {
      font-size: 18px;
    }
  }
  
  /* Extra large screens, TV */
  @media (min-width: 1201px) {
    .banner {
      height: 600px;
    }
    .banner img {
      height: 400px;
      padding: 60px;
    }
    .carousel-caption {
      bottom: 10%;
      padding: 20px 15px;
    }
    .labell {
      font-size: 22px;
    }
    .paraa {
      font-size: 20px;
    }
  }