.footer {
  background-color: #f8f9fa; /* Set background color */
  padding: 20px; /* Add padding for spacing */
  text-align: center; /* Align text to center */
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
}

.foot {
  max-width: 1200px; /* Set maximum width for content */
  margin: 0 auto; /* Center align content */
}

/* Ensure each paragraph is displayed on a separate line */
p {
  margin: 0; /* Remove margin */
}

@media (max-width: 768px) {
  .footer {
    padding: 20px 10px; /* Adjust padding for smaller screens */
  }
}
