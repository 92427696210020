.contact {
    background-image: url('../../LOGOS/logob2.jpg');
    background-size: cover;
    background-position: center;
    color: #ffffff; 
    padding: 50px 0; 
    margin:auto;
  }
  
  .contact-container {
    width: 500px;
    margin: auto;
    padding: 0 20px; 
    border-radius: 20px;
    gap: 50px;
    background: rgba(88, 80, 201, 0.479);
  }
  
  h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 30px;
    font-family: "Ubuntu", sans-serif;
    /* background-color: #2db6d88e; */
  }
  
  .contact-form {
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  textarea {
    height: 150px; 
  }
  
  button[type="submit"] {
    background-color: #007bff; 
    color: #ffffff; 
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3; 
  }
  
  /* ContactPage.css */
/* ... your existing styles ... */

@media (max-width: 768px) {
  .contact {
    padding: 30px 0;
  }

  .contact-container {
    width: 90%; /* Scale down container width */
    margin: 0 auto;  /* Center the container */
    padding: 20px; 
  }

  h2 {
    font-size: 2rem; /* Slightly smaller heading */
  }
}

@media (max-width: 480px) {
  .contact {
    padding: 20px 0; 
  }

  .contact-container { 
    width: 95%; 
  }

  h2 {
    font-size: 1.8rem;
  }
}
