/* PhotoGalleryPage.css */
.pho-container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.pho-gallery-category {
  margin-bottom: 40px;
}

.pho-gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 20px;
}

.flip-card {
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 300px;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  cursor: pointer;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
  overflow: hidden;
}

.flip-card-front img,
.flip-card-back img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.video-gallery-category {
  margin-bottom: 40px; /* Add spacing */
}

.video-gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
}

.video-gallery-item {
  border-radius: 10px; /* Add a border-radius if you like */
  overflow: hidden; /* Hide the extra parts of the embed */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); 
}

.video-gallery-item .fb-video {
  width: 100%;   
  height: auto;  
  max-height: 210px; /* Introduce a max-height for control */
}

@media (max-width: 480px) {
  .video-gallery-grid {
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  }

  .video-gallery-item .fb-video {
    width: 100%;     /* Occupy full width */
    height: auto;    /* Automatically maintain aspect ratio */
  }
}


@media (min-width: 481px) and (max-width: 768px) {
  .video-gallery-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  .video-gallery-item .fb-video {
    width: 100%; 
    height: auto;  
  }
}

/* Tablets and Small Laptops */
@media (min-width: 769px) and (max-width: 1024px) {
  .video-gallery-grid {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
  .video-gallery-item .fb-video {
    width: 100%; 
    height: auto;  
  }
}

/* Larger Laptops */
@media (min-width: 1025px) and (max-width: 1200px) {
  .video-gallery-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  .video-gallery-item .fb-video {
    width: 100%; 
    height: auto;  
  }
}

/* Larger Screens */
@media (min-width: 1201px) {
  .video-gallery-grid {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }
  .video-gallery-item .fb-video {
    width: 100%; 
    height: auto;  
  }
}

