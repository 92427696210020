/* Container styling */
.container {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 1200px;
  height: auto;

}

/* Image styling */
.img-container {
  width: 50%; /* Set image container to take up 50% of the container's width */
  max-width: 500px; /* Limit maximum width */
  height: auto;
  /* margin: 0 auto; */
}

.img-container img {
  max-width: 100%; /* Make image responsive */
  height: auto;
  border-radius: 10px; /* Rounded border radius */
}

/* Paragraph box styling */
.paragraph-box {
  width: 50%; /* Set paragraph box to take up 50% of the container's width */
  max-width: 500px; /* Limit maximum width */
  padding-left: 50px; 
  /* margin: 0 auto; */
  
}

.paragraph {
  font-family: "Ubuntu", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  padding-bottom: 15px;
  color: #333;
  line-height: 1.6; /* Added space between lines */
}

.gopi {
  padding-top: 20px;
}

@media screen and (max-width: 767px) {
  .container {
    flex-direction: column; /* Stack items vertically on smaller screens */
  }

  .img-container,
  .paragraph-box {
    width: 100%; /* Full width on smaller screens */
    max-width: none; /* Remove max-width */
    padding-left: 0; /* Remove padding on smaller screens */
  }

  .paragraph-box {
    padding-top: 20px; /* Add some space between items */
  }

  .paragraph {
    font-size: 15px;
    font-weight: 600;
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
  }
}