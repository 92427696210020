.header {
  background-color: #007bff; /* Set background color */
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative; 
  z-index: 1; 
}

.hamburger {
  z-index: 3;
}

.logo {
  width: 120px; /* Adjust width as needed */
  height: 55px; /* Adjust height as needed */
  background-image: url('../../LOGOS/logo.png');
  background-size: cover; /* Adjust background size */
  background-repeat: no-repeat; /* Prevent background from repeating */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.nav {
  display: flex;
  align-items: center;
}

.hamburger {
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 2; /* Ensure the hamburger is above other elements */
  transition: transform 0.3s ease; /* Add transition effect for smooth animation */
}

.hamburger span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #fff; /* Set hamburger color to white */
  margin: 5px 0;
}

.nav-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative; /* Ensure proper stacking context for dropdown */
}

.nav-list.show {
  display: flex;
}

.nav-item {
  background-color: rgba(255, 255, 255, 0.1); /* Set background color with opacity */
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Add transition effect for background color */
}

.nav-item:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Lighten background color on hover */
}

@media (max-width: 768px) {
  .nav-list {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    position: absolute;
    top: 70px; /* Adjust top position to account for header height */
    right: 0;
    background-color: #007bff; /* Add background color for dropdown */
    border-radius: 15px; /* Add border radius for dropdown */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
    z-index: 1; /* Ensure the dropdown is above other elements */
    transition: opacity 0.3s ease, transform 0.3s ease; /* Add transition effects */
    opacity: 0; /* Initially hide the dropdown */
    transform: translateY(-20px); /* Move the dropdown up */
  }

  .nav-list.show {
    display: flex;
    opacity: 1; /* Show the dropdown */
    transform: translateY(0); /* Move the dropdown down */
  }

  .nav-item {
    margin: 10px 0;
  }

  .hamburger {
    display: block;
  }
}
