/* General Styles */
/* body {
  font-family: "Arial", sans-serif; 
  margin: 0; 
} */

.about-us-page {
  max-width: 900px;
  margin: 30px auto;  /* Center content with top/bottom margin */
  padding: 20px;
  text-align: center; /* Center text elements within the page */
}
.about-container {
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center elements horizontally */
  text-align: center; /* Center text within the page */
}
.about-us-page h1 {
  font-size: 3em; /* Make the heading prominent */
  margin: 20px 20px;
}

.main-image {
  max-width: 100%;
  height: auto;         /* Maintain image aspect ratio */
  border-radius: 10px;  /* Softly rounded corners */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
}

/* Responsive Adjustments (Media Queries) */
@media (max-width: 600px) {
  .about-us-page h1 {
    font-size: 1.8em; /* Smaller heading on smaller screens */
  }
}

@media (max-width: 481px) {
  .about-us-page h1 {
    font-size: 1.2em; /* Smaller heading on smaller screens */
  }
}
